/**
 * Mixin to be used for using service.
 *
 * @returns {object}
 */
import { movingInternationalES } from 'chimera/moving/service'

export default {
  computed: {
    /**
     * @returns {*}
     */
    service() {
      return this.$store.state.service
    },

    /**
     * @returns {boolean}
     */
    serviceIsInternational() {
      return movingInternationalES.id === this.service.id
    },
  },
}
