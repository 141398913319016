<template>
  <div>
    <AddressFormGroup v-on="$listeners" />

    <ServiceField ref="service" hidden v-on="$listeners" />
    <MarketSegmentField ref="marketSegment" hidden v-on="$listeners" />
  </div>
</template>

<script>
import AddressFormGroup from 'chimera/moving/components/form/parts/address/AddressFormGroup'
import { movingDefaultBE } from 'chimera/moving/service'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import MarketSegmentField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentField.vue'

export default {
  name: 'MovingFromAddressFormPart',

  components: {
    AddressFormGroup,
    MarketSegmentField,
    ServiceField,
  },

  /**
   */
  mounted() {
    // Set default service to `moving-consumer`
    this.$refs.service.setValue(movingDefaultBE.id)
    this.$refs.marketSegment.setValue('Particulier')
  },
}
</script>
